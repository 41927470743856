.footer-container {
  text-align: center;
  margin-top: 1.5em;
  margin-bottom: 8vh;
}

.social-link * {
  text-decoration: none;
  color: inherit;
  font-weight: 500;
  font-size: 0.9em;
}

@media only screen and (min-width: 768px) {
  .footer-container {
    max-width: 1050px;
    margin: auto;
    margin-bottom: 8vh;
    margin-top: 1.5em;
  }
}