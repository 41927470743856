.cart-page {
  padding: 0 10px 0 10px;
  margin-top: 24px;
  height: 80vh;
}

.cart-main {
  max-width: 400px;
  margin: auto;
}

.cart-header {
  text-align: left;
  margin-left: -10px;
  padding: 0 7%;
}

.cart-list {
  padding-inline-start: 0;
}

.cart-item-container {
  list-style: none;
  display: flex;
  max-width: 400px;
  min-height: 60px;
  margin: auto;
  margin-bottom: 36px;
  border-radius: 20px;
}

.cart-img-container img {
  box-shadow: -6px 12px 27px -11px #10101336;
  border-radius: 2px;
  width: 120px;
}

.cart-item-details {
  padding-left: 10px;
}

.cart-item-header {
  font-size: 10pt;
  margin: 0;
}

.cart-size-container {
  height: 16px;
}

.cart-size-count  {
  display: inline-block;
  font-size: 8pt;
  font-weight: 500;
}

.cart-main-details {
  width: 64px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.cart-price {
  display: block;
  margin-left: auto;
  margin-top: 0;
  text-align: center;
}

.cart-delete {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 36px;
  padding: 0;
  background-color: inherit;
  border: none;
  color: inherit;
  margin: auto;
  margin-bottom: 0;
}

.trash-icon {
  padding-top: 4px;
  cursor: pointer;
}

.total {
  font-weight: bold;
  text-align: right;
  font-size: 14pt;
}

.cart-no-content {
  height: 50vh;
  text-align: center;
  padding-top: 20vh;
}

.cart-pear {
  width: 100px;
  text-align: center;
  display: block;
  margin: auto;
  margin-bottom: 10px;
}

.empty-cart-logo {
  width: 100%;
}

.cart-empty-message {
  font-weight: 500;
}

.checkout-route-container {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.go-to-checkout {
  border-radius: 0;
  color: inherit;
  background-color: rgb(130, 220, 255);
  width: 180px;
  display: inline-block;
}

@media only screen and (min-width: 760px) {
  .cart-page {
    max-width: 600px;
    margin: auto;
  }

  .cart-header {
    padding: 0;
    margin-left: 0;
  }

  .cart-main {
    max-width: 600px;
  }

  .cart-item-container {
    max-width: 600px;
    justify-content: space-between;
  }

  .cart-item-header {
    font-size: 14pt;
  }

  .cart-size-count {
    font-size: 10pt;
  }

  .cart-main-details {
    padding: 2px;
  }

  .cart-img-container img {
    width: 160px;
  }
}

@media only screen and (max-width: 760px) {
  .cart-page {
    margin-top: 0;
    padding-top: 148px;
  }
}
