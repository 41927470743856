.gallery-area {
  padding-top: 148px;
}

.generated-images {
  display: flex;
  flex-flow: wrap;
  justify-content: space-evenly;
  margin-bottom: 6vh;
}

.img-container {
  display: inline-block;
  position: relative;
  width: 40vw;
}

.hover-background {
  width: 210px;
  height: 0px;
  border-radius: 2px;
  position: absolute;
  left: 20px;
  right: 0;
  top: 6px;
  transition: 150ms;
  background-color: #f8f9fe;
}

.img-container img {
  width: 100%;
  max-width: 420px;
  display: block;
  top: 0px;
  margin: auto;
  cursor: pointer;
  border-radius: 2px;
  box-shadow: -6px 12px 27px -11px #10101336;
}

.img-container h2 {
  text-align: center;
  font-size: 0.8em;
  margin-top: 0;
  margin-top: 4px;
  margin-bottom: 26px;
  cursor: pointer;
}

.gallery-type-header {
  text-align: center;
  font-size: 0.7em;
  position: absolute;
  left: 12px;
  margin: 0;
  margin-bottom: 14px;
}

.gallery-image-price {
  opacity: 1;
  position: absolute;
  left: 10px;
  margin-bottom: 14px;
  font-size: 32pt;
}

.page-container {
  text-align: center;
  padding-bottom: 50px;
}

.page-control {
  border: none;
  background-color: inherit;
  font-size: 12pt;
  cursor: pointer;
  font: inherit;
  font-family: inherit;
  font-weight: 500;
  color: inherit;
}

.gallery-no-content {
  min-height: 40vh;
  padding-top: 148px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-preloader {
  min-height: 40vh;
  padding-top: 148px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 760px) {
  .gallery-area {
    padding-top: 0;
  }

  .gallery-area .cloud-context {
    padding: 16px;
  }

  .generated-images {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    max-width: 1050px;
    margin: auto;
    margin-bottom: 80px;
  }

  .img-container {
    box-sizing: border-box;
    display: grid;
    position: relative;
    max-width: 300px;
    margin: 6px 0px;
    width: 260px;
    height: 280px;
  }

  .img-container * {
    transition-timing-function: ease-in-out;
  }

  .img-container img {
    grid-row: 1/3;
    width: 250px;
    margin-bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    transition: 150ms;
  }

  .img-container h2 {
    margin-top: 4px;
    margin-bottom: 18px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -12px;
    transition: 150ms;
  }

  .gallery-type-header {
    opacity: 0;
    margin-bottom: 0;
    bottom: 0;
    transition-delay: 150ms;
    font-size: 0.7em;
  }

  .gallery-image-price {
    opacity: 0;
    margin-bottom: 0;
    bottom: 0;
    transition-delay: 150ms;
  }
}