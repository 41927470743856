.bar-decal {
  background-color: #f8f9fe;
  color: #d9d9d9;
  font-size: 16pt;
}

.bar-decal-header {
  margin: auto;
  max-width: 1034px;
}

@media only screen and (max-width: 768px) {
  .bar-decal {
    margin-bottom: 20px;
  }

  .bar-decal-header {
    text-align: center;
  }

  .bar-decal-temp .bar-decal-header {
    text-align: left;
    margin-left: 5%;
  }
}