.main-header {
  background-color: #f8f9fe;
}

.main-header-nav {
  padding: 0px 7%;
  display: inline;
}

.main-brand-logo {
  display: block;
  max-width: 170px;
  margin: 0;
}

.brand-image {
  width: 100%;
}

.main-nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin: auto;
  max-width: 1032px;
  padding: 0 20px;
  height: 60px;
  margin-top: 1.4em;
}

.main-nav-links li {
  display: inline-block;
  text-align: center;
  font-size: 0.8em;
  font-weight: 500;
}

.main-nav-links li a {
  text-decoration: none;
  color: inherit;
  transition: all 120ms;
}

.cart-area {
  color: inherit;
  display: flex;
  position: relative;
  z-index: 1000;
  top: 0;
  right: 0;
  margin: 4%;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  -webkit-animation: cartColorFadeOut ease-in-out 200ms;
  -moz-animation: cartColorFadeOut ease-in-out 200ms;
  -o-animation: cartColorFadeOut ease-in-out 200ms;
  animation: cartColorFadeOut ease-in-out 200ms;
}

.cart-count {
  background-color: #ff1e62;
  border: 2px solid #f8f9fe;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  right: -4px;
  top: -4px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f8f9fe;
  text-align: center;
  font-size: 10pt;
  font-weight: 500;
  transition: all 120ms;
}


@media only screen and (min-width: 760px) {
  .main-header-nav {
    max-width: 1032px;
    margin: auto;
    padding: 0px 32px;
  }

  .main-nav-links li {
    font-size: 0.9em;
  }

  .main-nav-links li a:hover {
    color: rgb(132, 220, 255);
  }

  .main-nav-links .clothing-hover-link a:hover {
    color: rgb(39, 47, 78);
  }

  .cart-area {
    animation: none;
    background-color: rgb(132, 220, 255);
    margin-bottom: 4px;
    margin-right: 18%;
  }

  .cart-scroll {
    animation: none;
    position: relative;
  }
}

@media only screen and (max-width: 760px) {
  .main-header {
    position: absolute;
    top: 0;
  }
}

@media only screen and (max-width: 760px) {
  .main-header-nav {
    display: none;
  }
}

@-webkit-keyframes cartColorFadeIn {
  0% { background-color: inherit; }
  100% { background-color: rgb(132, 220, 255); }
}

@-moz-keyframes cartColorFadeIn {
  0% { background-color: inherit; }
  100% { background-color: rgb(132, 220, 255); }
}

@-o-keyframes cartColorFadeIn {
  0% { background-color: inherit; }
  100% { background-color: rgb(132, 220, 255); }
}

@keyframes cartColorFadeIn {
  0% { background-color: inherit; }
  100% { background-color: rgb(132, 220, 255); }
}

@-webkit-keyframes cartColorFadeOut {
  0% { background-color: rgb(132, 220, 255); }
  100% { background-color: inherit; }
}

@-moz-keyframes cartColorFadeOut {
  0% { background-color: rgb(132, 220, 255); }
  100% { background-color: inherit; }
}

@-o-keyframes cartColorFadeOut {
  0% { background-color: rgb(132, 220, 255); }
  100% { background-color: inherit; }
}

@keyframes cartColorFadeOut {
  0% { background-color: rgb(132, 220, 255); }
  100% { background-color: inherit; }
}
