.editor-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgba(36, 36, 36, 0.685);
  top: 0;
  left: 0;
  z-index: 3000;
}

.editor-background {
  z-index: 400;
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
}

.editor-field {
  background-color: rgb(36, 36, 36);
  color: #f8f9fe;

  -webkit-box-shadow: 0px 10px 34px -16px rgb(34, 34, 36);
  -moz-box-shadow: 0px 10px 34px -16px rgb(34, 34, 36);
  box-shadow: 0px 10px 34px -16px rgb(34, 34, 36);

  z-index: 500;
  overflow-y: scroll;
  max-height: 100vh;
}

.delete-field {
  min-height: 0;
  padding-bottom: 6%;
  margin-top: 10%;
}

.editor-button {
  background-color: rgb(207, 236, 250);
  color: rgb(36, 36, 36);
  -webkit-box-shadow: 0px 10px 34px -16px rgb(34, 34, 36);
  -moz-box-shadow: 0px 10px 34px -16px rgb(34, 34, 36);
  box-shadow: 0px 10px 34px -16px rgb(34, 34, 36);
}

.cancel-button {
  background-color: rgb(228, 17, 27);
  color: #f8f9fe;
}

.new-description {
  height: 80px;
  padding: 8px;
  font-family: inherit;
  font-weight: inherit;
  resize: none;
}

.size-container {
  margin: auto;
  margin-bottom: 20px;
  width: 86%;
  display: flex;
  justify-content: space-between;
}

.t-category {
  display: inline-block;
  width: 96px;
  margin-top: 4px;
  margin-bottom: 14px;
}

.t-label-radio {
  text-align: left;
}

.no-scroll {
  overflow-y: hidden;
}