.discover-header-image {
  position: relative;
  margin: auto;
  width: 100vw;
  max-width: 100%;
  max-height: 600px;
  object-fit: cover;
  object-position: top;
}

.article-main-header-container {
  padding: 2em 3em 0em 3em;
  margin: auto;
  text-align: center;
}

.article-main-header {
  font-size: 3.5em; 
  background-color: #f8f9fe;
  margin: 0;
  padding: 10px;
}

.discover-main-article {
  max-width: 600px;
  margin: auto;
  background-color: #f8f9fe;
}

.discover-main-article h1 {
  text-align: left;
  font-size: 2em;
}


.discover-main-article img {
  width: 600px;
  max-width: 100%;
}

.discover-main-article p {
  font-size: 14pt;
  line-height: 26px;
}

.discover-main-article p:first-child {
  margin-top: 0;
}

@media only screen and (max-width: 760px) {
  .discover-header-image {
    top: 10em;
  }
  .discover-article-container {
    background-color: initial;
  }
  .article-main-header-container {
    padding: 13em 1em 0em 1em;
    width: 82%;
    margin: auto;
    background-color: #f8f9fe;
    text-align: left;
  }
  
  .article-main-header {
    font-size: 2em;
  }

  .discover-main-article {
    font-size: 1.1em;
    padding: 1em;
  }

  .discover-main-article p {
    font-size: 11pt;
    line-height: 24px;
  }
}