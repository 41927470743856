.edit-container {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  grid-row: 1/3;
  z-index: 2000;
}
  
.edit-container button {
  width: 100%;
  height: 50%;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  background-color: rgba(158, 158, 158, 0.493);
}
.edit-container button:hover {
  background-color: rgba(158, 158, 158, 0.692);
}
.rename-button {
  grid-row: 1/2;
}

.delete-button {
  grid-row: 2/3;
}

@media only screen and (min-width: 760px) {
  .edit-container {
    display: none;
    height: 100%;
  }

  .img-container:hover .edit-container {
    display: block;
  }
}