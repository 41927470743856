.fade-in {
  animation: fadeIn ease 400ms;
  -webkit-animation: fadeIn ease 400ms;
  -moz-animation: fadeIn ease 400ms;
  -o-animation: fadeIn ease 400ms;
  -ms-animation: fadeIn ease 400ms;
  }
  @keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

.link-default,
.link-default * {
  color: rgb(39, 47, 78);
  text-decoration: none;
}