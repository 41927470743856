.details-main {
  margin-bottom: 10vh;
}

.details-container {
  width: 90%;
  margin: auto;
  display: flex;
  margin-top: 2%;
}

.details-container .cloud-context {
  width: 100%;
}

.details-image {
  margin-top: 24px;
  margin-bottom: 4%;
  width: 100%;
}

.details-image img {
  display: block;
  width: 100%;
  border-radius: 4px;
  box-shadow: -6px 12px 27px -11px #10101336;
  min-width: 250px;
  min-height: 250px;
}

.details-info-container {
  margin-top: 24px;
}

.details-main-header {
  line-height: 46px;
  margin: auto;
  font-size: 1em;
  font-size: 16pt;
  margin-bottom: 4px;
}

.details-type-header {
  font-size: 12pt;
  font-weight: 400;
  margin: 0;
}

.details-main-desc {
  font-weight: 400;
}

.details-price {
  font-size: 14pt;
  color:rgb(36, 39, 39);
  margin-top: 35px;
}

.details-size-container {
  margin: 18px 0;
  display: flex;
}

.size-selection {
  display: flex;
  text-align: center;
  min-width: 40px;
  min-height: 40px;
  padding: 0;
  margin: 0;
  margin-right: 16px;
  font-size: 12pt;
  background-color: inherit;
  border: 1px solid rgb(153, 159, 161);
  color: rgb(36, 39, 39);
  align-items: center;
  justify-content: center;
  font-family: inherit;
  cursor: pointer;
}

.selected-size {
  background-color: rgb(235, 235, 235);
  outline: none;
}

.selected-size:hover {
  background-color: rgb(235, 235, 235);
}

.no-size {
  pointer-events: none;
  border: 1px solid rgb(211, 216, 218);
  color: rgb(122, 131, 131);
}

.details-preloader {
  min-height: 40vh;
  padding-top: 148px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 760px) {
  .details-container {
    max-width: 1120px;
    margin: auto;
    margin-top: 5px;
  }

  .details-main {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    margin-top: 28px;
  }

  .details-image {
    margin-top: 0;
    width: 50%;
    min-width: 50%;
  }
  
  .details-image img {
    display: inline-block;
    width: 100%;
    min-width: 400px;
    min-height: 400px;
  }

  .details-info-container {
    display: inline-block;
    margin-top: 0;
    margin-left: 5%;
  }

  .details-main-header {
    font-size: 28pt;
  }

  .details-main-desc {
    font-size: 12pt;
    font-weight: 500;
    color: rgb(71, 79, 105);
  }
}

@media only screen and (max-width: 760px) {
  .details-container {
    padding-top: 148px;
    margin-top: 0;
  }
}
