.mobile-category-menu-container {
  text-align: left;
  padding-top: 7px;
  min-height: 300px;
}

.categories-menu-header {
  font-size: 16px;
}

.mobile-category-menu-list {
  margin-top: 28px;
}

.mobile-category-menu-list li a {
  font-size: 14px;
  color: rgb(98, 118, 126);
}

.details-enabled-color {
  background-color: rgb(237, 243, 245);
  transition: all 600ms;
}